import logo from './logo.svg';
import './App.css';
import './index.css';
import Footer from './Footer.js';
import './General.css';
import './color.css';
import Navbar from './Navbar/Navbar.js';
import MainImage from './MainImage/MainImage.js';
import About from './About/About.js';
import Publications from './Publications/Publications.js';
import CV from './CV/CV.js';
import {useState} from 'react';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-36R3TMRGV6"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  // const [dark,setDark]= useState(false);

  return (
    <div className="default-color">
		<body id="fullbody default-color">
			<Navbar/>
      	  	<div id="mainbody" class="body-property default-color">
        		<MainImage/>
    			<div id='list' class="py-10 space-y-10 default-color">
      	  	  		<About/>
      	  	  		<CV/>
      	  	  		<Publications/>
    			</div>
      	  	</div>
  	  	  	<Footer />
      	</body>
	</div>
  );
}

export default App;

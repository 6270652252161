import { Component } from "react"
import Section from './../Section'
import SubSection from './../SubSection'
import data from './../info.json'
import {MdEmail} from "react-icons/md"
import IconText from './../Utils/IconText'
import {
	SiHaskell,
	SiPython,
	SiCplusplus,
	SiCsharp,
	SiC,
	SiJulia,
	SiPytorch,
	SiNumpy,
	SiPandas,
	SiVim,
	SiMarkdown,
	SiLatex,
	SiGit,
	SiGnubash,
	SiFigma
} from 'react-icons/si';

const AboutSubText = ({ text }) => <p className="sub-section-body"> {text} </p>

const AboutMail = ({ mail }) => (
	<IconText 
		icon={<MdEmail className="col-span-2 sub-section-body "/>}
		text={<p className="col-span-9 sub-section-body">{mail}</p>}
	/>
);


const AboutPrograms = ({ icon, name }) => (
    <div class="grid grid-rows-2 content-center gap-1 justify-items-center">
    	{icon}
		<p class="programming-language-name font-extralight"> {name} </p>
    </div>
);

const ProgrammingLanguages = () => (
	<div>
		<AboutSubText text={"Some programming languages that I like:"} />
    	<div class="grid grid-cols-6 content-center gap-1 justify-items-center pt-4">
			<AboutPrograms icon={<SiPython class="programming-language"/>} name={"Python"}/>
			<AboutPrograms icon={<SiC class="programming-language"/>} name={"C"}/>
			<AboutPrograms icon={<SiCplusplus class="programming-language"/>} name={"C++"}/>
			<AboutPrograms icon={<SiCsharp class="programming-language"/>} name={"C#"}/>
			<AboutPrograms icon={<SiJulia class="programming-language"/>} name={"Julia"}/>
			<AboutPrograms icon={<SiHaskell class="programming-language"/>} name={"Haskell"}/>
		</div>
	</div>
);

const Frameworks = () => (
	<div>
		<AboutSubText text={"Some frameworks I use daily:"}/>
		<div class="grid grid-cols-3 content-center gap-1 justify-items-center pt-4">
			<AboutPrograms icon={<SiPytorch class="programming-language"/>} name={"PyTorch"}/>
			<AboutPrograms icon={<SiNumpy class="programming-language"/>} name={"Numpy"}/>
			<AboutPrograms icon={<SiPandas class="programming-language"/>} name={"Pandas"}/>
		</div>
	</div>
);

const Tools = () => (
	<div>
		<AboutSubText text={"Some of my favourite tools:"}/>
    	<div class="hidden sm:grid grid-cols-6 content-center gap-1 justify-items-center pt-4">
			<AboutPrograms icon={<SiVim class="programming-language"/>} name={"Vim"}/>
			<AboutPrograms icon={<SiLatex class="programming-language"/>} name={"Latex"}/>
			<AboutPrograms icon={<SiMarkdown class="programming-language"/>} name={"Markdown"}/>
			<AboutPrograms icon={<SiGit class="programming-language"/>} name={"Git"}/>
			<AboutPrograms icon={<SiGnubash class="programming-language"/>} name={"Bash"}/>
			<AboutPrograms icon={<SiFigma class="programming-language"/>} name={"Figma"}/>
    	</div>
    	<div className="flex sm:hidden grid grid-rows-2 content-center justify-items-center pt-1">
    		<div class="grid grid-cols-3 content-center gap-1 justify-items-center pt-4">
				<AboutPrograms icon={<SiVim class="programming-language"/>} name={"Vim"}/>
				<AboutPrograms icon={<SiLatex class="programming-language"/>} name={"Latex"}/>
				<AboutPrograms icon={<SiMarkdown class="programming-language"/>} name={"Markdown"}/>
    		</div>
    		<div class="grid grid-cols-3 content-center gap-1 justify-items-center pt-4">
				<AboutPrograms icon={<SiGit class="programming-language"/>} name={"Git"}/>
				<AboutPrograms icon={<SiGnubash class="programming-language"/>} name={"Bash"}/>
				<AboutPrograms icon={<SiFigma class="programming-language"/>} name={"Figma"}/>
    		</div>
    	</div>
    </div>
);

class About extends Component{

    render(){
        return (
            <div
            	id="aboutMeSectionLink"
            	className="padding-space space-y-8"  >
            		<div>
                		<Section text="About me"/>
            			<p className="text-justify">{data['about']['description']}</p>
            		</div>
            		<div>
                		<SubSection text="Skills"/>
						<div class="space-y-4">
                			<ProgrammingLanguages/>
                			<Frameworks/>
							<Tools/>
						</div>
					</div>
            </div>
        );
    }
}

export default About;

import { Component } from "react"
import "../CV/CV.css"
import SubSection from './../SubSection'
import Section from './../Section'
import IconText from './../Utils/IconText'
import data from './../info.json'
import {MdLocationOn} from 'react-icons/md'
import {BsFillCalendarWeekFill} from 'react-icons/bs'

const AboutSubText = ({ text }) => <p className="sub-section-body"> {text} </p>;

var location = (text) => "📍\t" + text;
var date = (text) => "🗓\t" + text;

const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('cv_simone_scaboro.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'cv_simone_scaboro.pdf';
                alink.click();
            })
        })
    }

const ElementEducation = ({ elem }) => (
	<div>
		<h1 class="elem-first">{elem.title}</h1>
		<div class="col-span-9">
				<div class="flex md:hidden grid grid-rows-2">
				<IconText
					icon={<MdLocationOn class="publication-date"/>}
					text={<h1 class="italic font-light">{elem.location}</h1>}
				/>
				<IconText
					icon={<BsFillCalendarWeekFill class="publication-date"/>}
					text={<h1 class="publication-date">{elem.date}</h1>}
				/>
			</div>
			<div class="hidden md:flex justify-between w-full content-center justify-items-center">
				<IconText
					icon={<MdLocationOn class="publication-date"/>}
					text={<h1 class="italic font-light">{elem.location}</h1>}
				/>
				<IconText
					icon={<BsFillCalendarWeekFill class="publication-date"/>}
					text={<h1 class="publication-date">{elem.date}</h1>}
				/>
			</div>
		</div>
	</div>
);


const ElementCareer = ({ elem }) => (
	<div>
		<h1 class="elem-first">{elem.title}</h1>
		<div class="col-span-9">
			<div class="flex justify-between w-full content-center justify-items-center">
					<div class="flex  items-center space-x-2">
						<div class="flex-shrink-0">
							<MdLocationOn class="publication-date"/>
						</div>

						{/* SM */}
						<h1 class="flex sm:hidden publication-title-small">{elem.company}</h1>

						{/* MDa and above */}
						<div class="hidden sm:flex min-w-0">
							<h1 class="publication-title font">{elem.company}</h1>
							<div class="flex items-center space-x-2">
								<h1 class="pl-2 italic font-light">  {elem.location}</h1>
							</div>
						</div>
					</div>
					<IconText
						icon={<BsFillCalendarWeekFill class="publication-date"/>}
						text={<h1 class="publication-date">{elem.date}</h1>}
					/>
			</div>
			<h1 class="flex sm:hidden italic font-light ">{elem.location}</h1>
			<p class="text-justify font-light "> {elem.description}</p>
		</div>
	</div>
);


class CV extends Component{

    render(){
        return (
			<div id="cvSectionLink" class="padding-space space-y-8">
					<div id="cvSection">
                		<Section text="CV"/>
                		<SubSection text="Education"/>
            			{data['education'].map((elem) => (<ElementEducation elem={elem}/>))}
					</div>
					<div>
                		<SubSection text="Career"/>
            			{data['career']
            					.filter(elem => elem.show == 1)
            					.map((elem) => (<ElementCareer elem={elem}/>))}
					</div>
				{/* <button className="hover:italic" onClick={onButtonClick}> */}
				{/*                 	download CV  */}
				{/*             	</button> */}
			</div>
        );
    }
}

export default CV;

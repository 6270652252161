const IconText = ({ icon, text }) => (
	<div class="flex  items-center space-x-2">
		<div class="flex-shrink-0">
			{icon}
		</div>

		<div class="flex min-w-0">
			{text}
		</div>
	</div>
);

export default IconText;



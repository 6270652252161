import { Component } from "react";
import "../General.css"
import "../Navbar/Navbar.css"
import "../color.css"
import day from '../imgs/light_mode.webp';
import night from '../imgs/dark_mode.webp';
import Switcher from "../Switcher.js";

class NavbarDesktopHome extends Component {
  render() {
    return (
      <div className="flex">
          <a href="#" className="flex items-center py-4">
            <div id="home" className="home-button">Simone Scaboro</div>
          </a>
      </div>
    );
  }
}

class NavbarElement extends Component{
  render() {
    return <div id={this.props.id} className="standard-menu-navbar-button">{this.props.text}</div>;
  }
}

class NavbarElementMobile extends Component{
  render() {
    return <div id={this.props.id} className="standard-menu-navbar-button-mobile">{this.props.text}</div>;
  }
}

// class NavbarDarkModeButton extends Component{
//   constructor(props) {
//     super(props);
//     this.enableDisable = this.enableDisable.bind(this);
//     
//     this.state = {
//       isDark: document.querySelector("html").classList.contains("dark"),
//       icon: document.querySelector("html").classList.contains("dark") ? day : night
//     }
//     
//   }
//
//   enableDisable() {
//     const page = document.querySelector("html");
//     page.classList.toggle("dark");
//     this.setState({icon: this.state.isDark ? night : day});
//     this.state.isDark = document.querySelector("html").classList.contains("dark");
//     this.props.setDark(!this.props.dark);
//   }
//
//   render() {
//     return (
//       <div className={this.props.classes}>
//         <button id="dark_mode_button_sm" onClick={this.enableDisable}>
//           <img id="dark_mode_img_sm" src={this.state.icon}/>
//         </button>
//       </div>
//     );
//   }
// }


class NavbarMobileMenu extends Component {
  render() {
    return (
      	<div className="hidden md:hidden navbar-background mobile-menu z-50">
        <ul className="position: absolute navbar-background w-full">
          <li><NavbarElementMobile id="#aboutMeSection" text="About"/></li>
          <li><NavbarElementMobile id="#cvSection" text="CV"/></li>
          <li><NavbarElementMobile id="#publicationSection" text="Publications"/></li>
		  	<li>
				<div id={this.props.id} className="standard-menu-navbar-button-mobile">
            		<Switcher/>
				</div>
		  </li>
          	{/*<li><NavbarDarkModeButton classes="standard-menu-navbar-button-dark-mode"/></li>*/}
        </ul>
      </div>
    );
  }
}

class NavbarMobileMenuIcon extends Component {
  constructor(props) {
    super(props);

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const menu = document.querySelector(".mobile-menu");
    menu.classList.toggle("hidden");
  }

  render() {
    return (
    	<div className="md:hidden flex items-center px-2">
    	<button className="outline-none mobile-menu-button" onClick={this.handleClick}>
      	  <svg className="navbar-menu-button"
        	x-show="!showMenu"
        	fill="none"
        	//stroke-linecap="round"
        	//stroke-linejoin="round"
        	stroke-width="2"
        	viewBox="0 0 24 24"
        	stroke="currentColor"
      	  >
        	<path d="M4 6h16M4 12h16M4 18h16"></path>
      	  </svg>
    	</button>
    </div>
    );
  }
}

class Navbar extends Component {
  	  render() {
  	  	  return(
      	  	  <nav className="body-property z-50000 default-color fixed top-0 w-full bg-opacity-90 dark:bg-opacity-90">
      	  	  	  <div className="max-w-6xl mx-auto ">
      	  	  	  	  <div className="flex justify-between">
          	  	  	  	  <NavbarDesktopHome/>
          	  	  	  	  <div className="hidden md:flex items-center">
					  	  <a href="#aboutMeSectionLink">
              	  	  	  	  <NavbarElement id="about" text="About"/>
            			  </a>
						  <a href="#cvSectionLink">
              	  	  	  	  <NavbarElement id="cv" text="CV"/>
            			  </a>
						  <a href="#publicationSectionLink">
              	  	  	  	  <NavbarElement id="publications" text="Publications"/>
            			  </a>
						  <div className="standard-menu-navbar-button">
            				<Switcher/>
						  </div>
					</div>

        <NavbarMobileMenuIcon/>
				</div>
			</div>
      <NavbarMobileMenu props={this.props}/>

		</nav>
    );
  }
}

export default Navbar;

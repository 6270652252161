import { Component } from "react"
import {GiTeacher} from 'react-icons/gi'
import {GiBookmarklet} from 'react-icons/gi'
import "../Publications/Publications.css"
import Section from './../Section'
import data from './../info.json'



const ElementPublication = ({ elem }) => (

		<div className="flex items-center space-x-4">
			<div className="flex-shrink-0">
				{
					elem.journal_or_conference === "conference"
						? <GiTeacher/> 
						: <GiBookmarklet/> 
				}
			</div>

			<div className="flex-1 min-w-0">
				<div className="col-span-9">
					<a href={elem.url}><h1 className="publication-title">{elem.title}</h1></a>
					<h2 className="publication-authors">{elem.authors}</h2>
					<div className="flex justify-between w-full content-center justify-items-center">
						<p className="publication-conference">{elem.conference}</p>
						<p className="publication-date">{elem.date}</p>
					</div>
				</div>
			</div>
		</div>

);


class Publications extends Component{

    render(){
        return (
			<div id="cvSectionLink" className="padding-space">
				<div id="cvSection">
                	<Section text="Publications"/>
                	<div className="space-y-5">
            			{data['publications'].map((elem) => (<ElementPublication elem={elem}/>))}
            		</div>
				</div>
			</div>
        );
    }
}

export default Publications;

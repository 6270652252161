import { Component } from "react";
import ProfileImage from "../imgs/simone_scaboro.jpg"
import "../MainImage/MainImage.css"
import { BsGithub, BsLinkedin} from 'react-icons/bs';
import { SiGooglescholar} from 'react-icons/si';

const MyIcon = ({ icon, link }) => ( <a href={link} > {icon} </a> );


const MailLink = () => {
	return <a class="font-light pt-2 md:pt-4" href="mailto:scaboro.simone@gmail.com">
		scaboro.simone@gmail.com
	</a>;
}


class MainImage extends Component {

    render(){
        return(
            <div className="flex justify-center pt-32 md:pt-28">
                <div className="grid grid-cols-1 content-center gap-y-3 justify-items-center">
                    {/* <img className="main-img" src={ProfileImage}></img> */}
                    <h1 className="name-column-text">Simone Scaboro</h1>
                    <div className="grid grid-cols-3 content-center gap-6 justify-items-center pt-2 md:pt-4">
            			<MyIcon icon={<BsGithub className="text-2xl md:text-4xl"/>} link={"https://github.com/simonescaboro"}/>
            			<MyIcon icon={<BsLinkedin className="text-2xl md:text-4xl"/>} link={"https://www.linkedin.com/in/simonescaboro/"}/>
            			<MyIcon icon={<SiGooglescholar className="text-2xl md:text-4xl"/>} link={"https://scholar.google.com/citations?user=uA9V13IAAAAJ&hl=it"}/>
            		</div>
					<MailLink/>
                </div>
            </div>
        );
    }
}

export default MainImage;

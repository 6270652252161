import { Component } from "react"

class SubSection extends Component{
    render(){
        return(
            <h2 
            className="text-2xl font-semibold ">
                {this.props.text}
            </h2>
        );
    }
}

export default SubSection;



import { Component } from "react"

class Section extends Component{
    render(){
        return <h1
        	className="text-3xl font-bold pt-2 pb-3">
        		{this.props.text}
        	</h1>;
    }
}

export default Section;



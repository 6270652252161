import { useState } from "react";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import useDarkSide from "./useDarkSide.js";
import './color.css';
  
export default function Switcher() {
    const [colorTheme, setTheme] = useDarkSide();
    const [darkSide, setDarkSide] = useState(
        colorTheme === "light" ? true : false
    );
  
    const toggleDarkMode = (checked) => {
        setTheme(colorTheme);
        setDarkSide(checked);
    };
  
    return (
        <>
            <DarkModeSwitch
                // style={{ marginBottom: "1rem" }}
                moonColor={"#f2f2f2"}
                sunColor={"#121212"}
                checked={darkSide}
                onChange={toggleDarkMode}
                size={23}
            />
        </>
    );
}

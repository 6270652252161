import { Component } from "react";
import './color.css';

class Footer extends Component {
  render() {
    return(
      <footer className="pt-14 pb-6 default-color text-opacity-25">
			  <div className="flex justify-items-center justify-center grid grid-rows-2 justify-center content-center">
          <p className="text-base default-color text-opacity-25">
            Website made by me using React and Tailwind 
          </p>
          <p className="text-base default-color text-opacity-25">
            (still WIP)
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
